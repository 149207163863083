<template>
  <div style="height: 100vh" v-loading="true" />
</template>

<script>
import { Message } from "element-ui";
import { getTaskForNode } from "@/api/nodes";
import store from "@/store";

export default {
  name: "CallflowNodeGate",
  props: {
    node_id: {
      required: true,
      type: [String, Number]
    }
  },
  created() {
    getTaskForNode({ node_id: this.node_id })
      .then(async ({ data }) => {
        let { ac_id, sp_id, task_id } = data;

        let val = await store.dispatch(
          "app/generateUserAccountSwitcherOptions",
          {
            sp_id,
            ac_id
          }
        );

        let { accountId } = val;

        let params = accountId ? `ac_id=${accountId}` : "";
        params = params ? `?${params}` : "";
        let path = `/build/callflow/${task_id}/${this.node_id}${params}`;
        this.$router
          .push({
            path
          })
          .catch(() => {});
      })
      .catch(err => {
        Message.error(err || "Has Error");
        this.$router.push("/login").catch(() => {});
      });
  }
};
</script>
